import { createSlice } from "@reduxjs/toolkit";
import { featchopportunitytype } from "../../api/OpportunityService/featchopportunitytype";


const initialState = {
  data: [],
  status: "idle", 
  error: null,
};

const opportunitytypeSlice = createSlice({
  name: "opportunitytypeDetail",
  initialState,
  reducers: {
    clearOrg: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(featchopportunitytype.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(featchopportunitytype.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = "success";
        state.error = null;
      })
      .addCase(featchopportunitytype.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error.message;
      });
  },
});

// export const { clearOrg } = opportunitytypeSlice.actions;
export default opportunitytypeSlice.reducer;