import { createAsyncThunk } from "@reduxjs/toolkit";
import dealvpsService from "../../services/dealvpsService";

export const featchActivityGeneric = createAsyncThunk(
  "GenericActivity/fetchGenericActivity",
  async (body, { rejectWithValue }) => {
    try {
      const userName = localStorage.getItem("userName");
      const res = await dealvpsService.post(
         `api/v1/general/query/GET_CHECKLIST_ACTIVITY`,
         body
      );
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
