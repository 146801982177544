import { createAsyncThunk } from "@reduxjs/toolkit";
import dealvpsService from "../../services/dealvpsService";

export const fetchData = createAsyncThunk(
  "outboundEmail/fetchData",
  async (_, { rejectWithValue }) => {
    try {
      const res = await dealvpsService.get(
        `/api/v1/general/get-all-table-data/Outbound Email`
      );
      return res.data;
    } catch (error) {
      return rejectWithValue(error?.resposnse?.data);
    }
  }
);

export const fetchDatawithTag = createAsyncThunk(
  "outboundEmailwithTag/fetchData",
  async ( TransTicketId, { rejectWithValue },) => {
    try {
      const res = await dealvpsService.get(
        `/api/v1/general/related/tickets/${TransTicketId}?relatedFormName=Outbound Email&start&size`
      );
      const data = res.data;

      // Exclude the last entry if it's an array and contains at least one element
      if (Array.isArray(data) && data.length > 0) {
        return data.slice(0, -1); // Return all elements except the last one
      }

      return data; // If data is empty or not an array, return as is
      
    } catch (error) {
      return rejectWithValue(error?.resposnse?.data);
    }
  }
);

