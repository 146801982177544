import { combineReducers } from "redux";
import languageReducer from "../reducer/localization/languageSlice";
import contentReducer from "../reducer/localization/contentSlice";
import menuReducer from "../reducer/togglemenu/togglemenu";

import langListReducer from "../reducer/localization/listOfLangSlice";
import RoleMappingSlice from "../reducer/roles/RoleMappingSlice";

import regionalData from "./localization/CountryRegionalSlice";
import languageKnownSlice from "./detailedProfile/languageKnownSlice";
import EditDataslice from "./../reducer/Edit/EditDataslice";
import ProjectDataSlice from "./../reducer/ProjectMasterSlice/ProjectDataSlice";
import IndividualSlice from "./../reducer/Slice/IndividualSlice";
import OrganizationSlice from "./../reducer/Slice/OrganizationSlice";
import JournalSlice from "./../reducer/Slice/JournalSlice";
import OrgNameAutoCompleteSlice from "./../reducer/Slice/OrgNameAutoCompleteSlice";
import IndividualNamedropdownSlice from "./../reducer/Slice/IndividualNamedropdownSlice";
import DealNameDropdownSlice from "./../reducer/Slice/DealNameDropdownSlice";
import DomainDropdownSlice from "./../reducer/Slice/DomainDropdownSlice";
import LocationdropdownSlice from "./../reducer/Slice/LocationDropdownSlice";
import OpportuniteGridSlice from "./../reducer/Slice/OpportuniteGridSlice";
import TransactionGridSlice from "./../reducer/Slice/TransactionGridSlice";
import DealGridSlice from "./../reducer/Slice/DealGridSlice";
import ProjectGridSlice from "./../reducer/Slice/ProjectGridSlice";
import PotentialMaster from "./../reducer/Slice/PotentialMasterSlice";
import OurRoleSlice from "./../reducer/Slice/OurRoleSlice";
import StatusSlice from "./../reducer/Slice/StatusSlice";
import InitiatorTypeSlice from "./../reducer/Slice/InitiatorTypeSlice";
import ClientSlice from "./../reducer/Slice/ClientSlice";
import TransactionTypeSlice from "./../reducer/Slice/TransactionTypeSlice";
import CurrencySlice from "./../reducer/Slice/CurrencySlice";
import DealTypeSlice from "./../reducer/Slice/DealTypeSlice";
import ProjectTypeSlice from "./../reducer/Slice/ProjectTypeSlice";
import OppertunityByIdSlice from "./../reducer/Slice/OppertunityByIdSlice";
import TransactionGetByIdSlice from "./../reducer/Slice/TransactionGetByIdSlice";
import DealGetByIdSlice from "./../reducer/Slice/DealGetByIdSlice";
import ProjectGetByIdSlice from "./../reducer/Slice/ProjectGetByIdSlice";
import AccountDetailsSlice from "./../reducer/Slice/AccountDetailsSlice";
import fundingNeedSlice from "./../reducer/Slice/fundingNeedSlice";
import InboundSlice from "./../reducer/Slice/InboundEmailSlice";
import OutboundSlice from "./../reducer/Slice/OutBoundEmailSlice";
import TagAppdropdownSlice from "../reducer/Slice/TagAppdropdownSlice";
import TagdropdownSlice from "../reducer/Slice/Tagdropdownslice";
import CustomTagSlice from "../reducer/Slice/CustomTagSlice";
import emailTagSlice from "../reducer/Slice/emailTagSlice";
import dealTagSlice from "../reducer/Slice/dealTagSlice";
import relatedTagSlice from "./Slice/relatedTagSlice";
import selectedTagsSlice from "../reducer/Slice/selectedTagSlice";
import InboundwithTagSlice from "./Slice/InboundEmailwithTagSlice";
import OutboundwithTagSlice from "./Slice/OutboundEmailwithTagSlice";
import dealMenuSlice from "./Slice/dealMenuSlice";
import  JournalwithTagSlice  from "./Slice/JournalwithTagSlice";
import  featchGenericSlice  from "./Slice/featchGenericSlice";
import  PhasemainGridSlice  from "./Slice/PhasemainGridSlice";
import  opportunitytypeSlice  from "./Slice/opportunitytypeSlice";
import  ApprovalStatusSlice  from "./Slice/ApprovalStatusSlice";
import  featchTaskGenericSlice  from "./Slice/featchTaskGenericSlice";
import  TaskmainGridSlice  from "./Slice/TaskmainGridSlice";
import  featchActivityGenericSlice  from "./Slice/featchActivityGenericSlice";
import  ActivitymainGridSlice  from "./Slice/ActivitymainGridSlice";

const rootReducer = combineReducers({
  
  languageKnown: languageKnownSlice,
  regionalData: regionalData,
  menu: menuReducer,

  langList: langListReducer,
  language: languageReducer,
  content: contentReducer,
  EditData:EditDataslice,

  roles: RoleMappingSlice,
  ProjectDetail:ProjectDataSlice,
  IndividualDetail:IndividualSlice,
  OrganizationDetail:OrganizationSlice,
  journalDetail:JournalSlice,
  OrgNameAutoDetail:OrgNameAutoCompleteSlice,
  IndividualNameAutoDetail:IndividualNamedropdownSlice,
  DealNameListDetail:DealNameDropdownSlice,
  DomainDetail:DomainDropdownSlice,
  LocationAutoDetail:LocationdropdownSlice,
  OpportuniteGridtDetail:OpportuniteGridSlice,
  TransactionGridtDetail:TransactionGridSlice,
  DealGridtDetail:DealGridSlice,
  ProjectGridtDetail:ProjectGridSlice,
  potentialMasterDetail:PotentialMaster,
  OurRoleSliceDetail:OurRoleSlice,
  ApplicationStatusDetail:StatusSlice,
  InitiatorTypeDetail:InitiatorTypeSlice,
  ClientDetail:ClientSlice,
  TransactionTypeDetail:TransactionTypeSlice,
  CurrencyDetail:CurrencySlice,
  DealTypeDetail:DealTypeSlice,
  ProjectTypeDetail:ProjectTypeSlice,
  opportunityByIdDetails:OppertunityByIdSlice,
  TransactionByIdDetails:TransactionGetByIdSlice,
  DealByIdDetails:DealGetByIdSlice,
  ProjectByIdDetails:ProjectGetByIdSlice,
  AccountsDetail:AccountDetailsSlice,
  FundingNeedDetail:fundingNeedSlice,
  inboundDetail:InboundSlice,
  outboundDetail:OutboundSlice,
  TagAppdropdownDetail:TagAppdropdownSlice,
  TagdropdownSlice : TagdropdownSlice,
  emailTagSlice : emailTagSlice,
  dealTagSlice : dealTagSlice,
  relatedTagSlice : relatedTagSlice,
  selectedTags: selectedTagsSlice,
  inboundDetailwithTag:InboundwithTagSlice,
  outboundDetailwithTag: OutboundwithTagSlice,
  journalDetailwithTag:JournalwithTagSlice,
  CustomDetail : CustomTagSlice,
  GenericchecklistDetail:featchGenericSlice,
  PhasemainGridDetail:PhasemainGridSlice,
  opportunitytypeDetail:opportunitytypeSlice,
  ApprovalStatusDetail:ApprovalStatusSlice,
  dealMenuSlice: dealMenuSlice,
  CustomDetail : CustomTagSlice,
  GenericTaskListDetail:featchTaskGenericSlice,
  TaskmainGridDetail:TaskmainGridSlice,
  GenericActivityListDetail:featchActivityGenericSlice,
  ActivitymainGridDetail:ActivitymainGridSlice

});

export default rootReducer;
