import { createSlice } from "@reduxjs/toolkit";
import { featchActivityGeneric } from "../../api/ActivityService/featchActivityGeneric";




const initialState = {
  data: [],
  status: "idle", 
  error: null,
  
};


const featchActivityGenericSlice= createSlice({
    name: "GenericActivityListDetail",
    initialState,
   reducers: {
    clearData: () => initialState
    },
    extraReducers: (builder) => {
      builder
        .addCase(featchActivityGeneric.pending, (state) => {
          state.status = "loading";
          state.error = null;
        })
        .addCase(featchActivityGeneric.fulfilled, (state, action) => {
          console.log("featchActivityGeneric",action.payload);
          state.data = action.payload;
          state.status = "success";
          state.error = null;
        })
        .addCase(featchActivityGeneric.rejected, (state, action) => {
          state.status = "error";
          state.error = action.error.message;
        });
    },
  });

export default featchActivityGenericSlice.reducer;





