import { createAsyncThunk } from "@reduxjs/toolkit";
import dealvpsService from "../../services/dealvpsService";

export const featchTaskmainGrid = createAsyncThunk(
    "TaskmainGrid/fetchTaskmainGrid",
    async (TransactionId, { rejectWithValue }) => {
      try {
        const userName = localStorage.getItem("userName");
        const res = await dealvpsService.post(
           `api/v1/general/query/GET_TASK_DETAILS_BY_DEAL_PROJECT_ID`,
           { "ticketId":TransactionId }
        );
        return res.data;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );
  
