import { createSlice } from "@reduxjs/toolkit";
import { featchGeneric } from "../../api/PhaseService/featchGeneric";
import { featchTaskGeneric } from "../../api/TaskService/featchTaskGeneric";




const initialState = {
  data: [],
  status: "idle", 
  error: null,
  
};


const featchTaskGenericSlice= createSlice({
    name: "GenericTaskListDetail",
    initialState,
   reducers: {
    clearData: () => initialState
    },
    extraReducers: (builder) => {
      builder
        .addCase(featchTaskGeneric.pending, (state) => {
          state.status = "loading";
          state.error = null;
        })
        .addCase(featchTaskGeneric.fulfilled, (state, action) => {
          console.log("featchTaskGeneric",action.payload);
          state.data = action.payload;
          state.status = "success";
          state.error = null;
        })
        .addCase(featchTaskGeneric.rejected, (state, action) => {
          state.status = "error";
          state.error = action.error.message;
        });
    },
  });
  export const { clearData } = featchTaskGenericSlice.actions;
export default featchTaskGenericSlice.reducer;





